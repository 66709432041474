<template>
   <div>
        <van-grid :column-num="3">
             <van-grid-item  v-for="(item,index) in list" :key="index" class="box-wrap" @click="toNav(item.path)" >
                <i :class="item.icon+' iconfont '"></i>
                <p class="text-base font-16 mt10">{{item.name}}</p>
             </van-grid-item>
       </van-grid>

       <div class="tips font-12 text-grey">
       </div>
   </div>
</template>

<script>
import { Grid, GridItem } from 'vant';


export default {
   components: {
        [Grid.name]:Grid,
        [GridItem.name]:GridItem
   },
   data(){
       return{
          activty_id:0,
          list:[{name:'爆款订单',icon:'icon-baokuan',path:'/activity/marketing/hot/order'},{name:'拼团订单',icon:'icon-iconfonttuan',path:'/activity/marketing/group/order'}]
       }
   },

    mounted: function() {
        this.activty_id = this.$route.query.id;
        // this.loadData();
    },
   methods:{
      toNav(path){
          this.$router.push({path:path,query:{activity_id:this.activty_id}});
      }
   }
}
</script>

<style lang="less">
   .box-wrap{
      
      .iconfont{
            color: #333;
            font-size: 28px;
      }
      .tips{
          padding: 0.5rem ;
      }
     
   }
</style>